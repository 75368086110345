<template>
  <div>
    <el-dialog :title="uploadFilesTitle" 
                center
                width="30%"
                :modal-append-to-body="false"
                :visible="uploadFilesVisible"
                @close="uploadFilesDialogClose">
      <p class="fs6 mb20">请先下载导入模板的模板文件，按照文件内的内容编辑后再"选择文件上传"即可批量导入。</p>
      导入模板：<a class="cblue ml20" :href="excelFile" download="导入模板-壹脉.xlsx">点击下载</a>
      <div class="d-flex w320 posre">
        <el-upload
          class="mt20 mb20"
          action=""
          :before-upload="handleUpload"
          :on-remove="handleRemove"
          :limit="1"
          :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            
        </el-upload>
      </div>
      <div class="mt40 flex-c-c">
        
        <el-button type="primary" plain @click="uploadFilesDialogClose">
          取消
        </el-button >
        <el-button type="primary" @click="onConfirmUploadFiles">
          确认
        </el-button >
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "commonTable",
  props:{
    uploadFilesTitle:{ //弹出框样式
      type:String,
      default: ''
    },
    dialogCurrentPage:{ //当前页数
      type :Number,
      default: 1
    },
    uploadFilesVisible:{ // 弹出框显隐
      type :Boolean,
      default: false
    },
    dialogCurrentChange:{
      type :Function,
      default: () => {}
    },
    
  },
  data() {
    return {
      chooseCardId:null,
      excelFile:this.$store.state.ossUrl + 'yimai_photos/excelFile/excelFile.xlsx',
      uploadUrl:this.$store.state.uploadingUrl,
      fileList:[],
      fileName:''
    };
  },
  methods: {
    downloadPDf() {
      window.location.href="../../static/excelFile/excelFile.xlsx" ; 
    },
     /**@method 监听下一页并调用父组件方法 */
    currentChange(val) {
      console.log(val)
      this.$emit('dialogCurrentChange',val);
      /* handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      } */
    },
    //弹框页面切换
    uploadFilesDialogClose(val){
      this.$emit('uploadFilesDialogClose',val);
    },
    checkTap(id){
      this.chooseCardId = null
      this.chooseCardId = id
    },
    handleUpload(file){
      console.log(file)
      this.uploadFile = file
      this.fileList.push({
        name:file.name,
        url:''
      })
    },
    handleRemove(){
      this.uploadFile = ''
    },
    async onConfirmUploadFiles(){
      const loading = this.$loading({
        lock: true,
        text: '导入中，请等待',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const fromData = new FormData()
      fromData.append('file',this.uploadFile)
      const result = await axios({
        headers:{
          "Content-Type": "multipart/form-data"
        },
        method:'post',
        url:'/api/crmPc/customer/importCustomerv2',
        data:fromData
      })
      if(result.code == 200){
        this.$message.success(result.message)
        this.$emit('UploadFilesSuccess')
        loading.close();
      }else{
        this.$message.error(result.message)
        loading.close();
      }
      this.$emit('onConfirmUploadFiles');
    }
  },
};
</script>

<style lang="scss" scoped>

/deep/ .el-upload--text {
  width: 90px;
  height: 36px;
  border: 0px;
}

</style>